import React, { Component,useState,useRef,useEffect } from 'react';
import { withRouter } from "react-router";
import { Select } from 'antd';
import { Button } from "antd"; 
import { AudioFilled,DeleteOutlined, BorderOutlined, DeleteFilled } from "@ant-design/icons";
import customFetch from "./apicall/api";
class SupportTicketForm extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      categories: [],
      category:'Feature/functionalitynotworking',
      summary:'',
      priority:'2',
      description:'',
      file:'',
      warning_msg:'',
      user_id:'',
      designation_name:'',
      users:[],
      assignee:null,
      branches:[],
      disabled:false,
      api_token: localStorage.getItem('api_token'),
      audio:null
    };
  }

  
  async componentDidMount(){
    //static values for categories(will be removed in the future)
    try{
      const _category = ['Feature/functionalitynotworking','Datacorrection','Appdown','Login/role/access/mapping issue','Reports/MISrelated','Query/clarification'];
      this.setState({categories:_category});

      const uid = localStorage.getItem("in_userid");
      if (!uid) {
        localStorage.clear();
        this.props.history.push("/");
      }
      const users = await this.fetchUsers();
      const branches = [...new Set(users.map(item => item.branch_name))];
      this.setState({user_id:uid,users:users,branches:branches});
    }
    catch(e){
      console.error(e);
    }
  }

  logout = ()=>{
    localStorage.clear();
    this.props.history.push("/");
  }

  handleChange = (e)=>{
    const name = e.target.name;
    let value = e.target.value;
    if(name == 'file')
      value = e.target.files[0];
    this.setState({[name]:value});
  }  

  fetchUsers = async ()=>{
    
    const  api_token  = localStorage.getItem('api_token');
    const _headers = new Headers();

    const response = await customFetch(process.env.REACT_APP_URL+'/get/users', {
        method: 'GET',
        headers: {
          "api-token": api_token,
        }
      });
    
        
    if(response.status != 200)
        throw "Internal Server Error"
    let data = await response.json();
    return data;
  }
  
  handleSubmit =(e)=>{
    this.setState({disabled:true});
    e.preventDefault();
    // console.log(process.env.REACT_APP_collection_hostname);
    let warning_msg = "";
    // if(this.state.assignee == null){
    //   warning_msg = "Please select the assignee id";
    //   this.setState({warning_msg,disabled:false});
    //   return;
    // }
    if(this.state.summary == ''){
      warning_msg = "Please enter the issue summary";
      this.setState({warning_msg,disabled:false});
      return;
    }
    if(this.state.category == ''){
      warning_msg = "Please choose the issue category";
      this.setState({warning_msg,disabled:false});
      return;
    }
    if(this.state.priority == ''){
      warning_msg = "Please choose the issue priority";
      this.setState({warning_msg,disabled:false});
      return;
    }
   
    if(this.state.description == ''){
      warning_msg = "Please write the issue description";
      this.setState({warning_msg,disabled:false});
      return;
    }
    // if(this.state.file == ''){
    //   warning_msg = "Please select the file to be uploaded";
    //   this.setState({warning_msg,disabled:false});
    //   return;
    // }
    
    const assignee = this.state.users.find((user)=> user.userid == this.state.user_id);

    const data = {
      "reporter_id":this.state.user_id,
      "summary":this.state.summary,
      "description":this.state.description,
      "priority":this.state.priority,
      "user_type":assignee.designation_name,
      "category":this.state.category,
      "assignee_id":assignee.userid
    }
    
    var _headers = new Headers();
    
    const  api_token  = localStorage.getItem('api_token');
    _headers.append("Content-Type", "application/json");
    _headers.append("api-token",api_token)
    // _headers.append("Accept", "application/json");
      
    customFetch(process.env.REACT_APP_URL + "/issue/create/ticket", {
      method: 'POST',
      body: JSON.stringify(data),
      headers: _headers
      }).then(res=>res.json())
      .then(async res =>{
        console.log(res);
        if(res.status_code != 200)
          return;

          const formData = []
          if(this.state.file!=""){
            const file = new FormData();
            file.append("file", this.state.file);
            file.append("issueIdOrKey",res["data"]["id"]);
            formData.push(file)
          }
          if(this.state.audio){
            const audiofile = new FormData();
            let blob = await customFetch(this.state.audio).then(r => r.blob());
            audiofile.append("file",blob,'voice_description_'+Math.floor(Math.random()*100)+'.mka');
            audiofile.append("issueIdOrKey",res["data"]["id"]);
            formData.push(audiofile)
          }

          const promises = await Promise.all(formData.map((input)=>{
            return customFetch(process.env.REACT_APP_URL + "/issue/attachment", {
              method: "POST",
              body: input,
              headers: {
                "api-token": this.state.api_token,
              },
            })
          }))
  
          const data = await Promise.all(promises.map(r => r.json()))
          return data.flat()

      }).then((res)=>{
        console.log(res);
        this.props.history.push('/supportticketlist');
      })
      .catch((error) => console.log("error", error));;
    
    // let res = {
    //   "data": {
    //       "id": "11066",
    //       "key": "FIEL-19",
    //       "self": "https://navadhan.atlassian.net/rest/api/3/issue/11066"
    //   },
    //   "message": "issue ticket created successfully",
    //   "status_code": 200
    // }

    
  }
  setAudio=(audiourl)=>{
    this.setState({audio:audiourl})
  }
  render() {
    return (
      <>
        <div className="container support_nav_section">
          <div className="support_navbar">
            <button className='button back_button' onClick={() => this.props.history.goBack()}>
                <img
                  src={require("../assets/images/back.png")}
                />
            </button>
            <span>Help Center</span>
            <button className="button" onClick={this.logout}>
              <img
              src={require("../assets/images/power-off.png")}
              alt=""
              />
            </button>
          </div>
          <img src={require('../assets/images/help-centre.png')} />
        </div>
        <div className="container support_form_body">
          <div className="d-flex align-items-center justify-content-center mt-2">
            Support Ticket
          </div>
          <form  className='support_form'>
            {/* <Select onChange={(value)=>{this.setState({assignee:value})}} showSearch 
                optionFilterProp="children"
                mode="default" placeholder={'Assignee'} name='assignee' size='large' style={{ width: "90%",borderRadius:"5px" }}>
              {
                  this.state.branches.map((branch)=>{  
                    
                    return <Select.OptGroup key={branch}  label={branch}>
                      {
                        this.state.users.map((user) =>
                          {
                            if(branch == user.branch_name)
                            return <Select.Option value={user.userid} label={user.employee_name}  key={user.userid}>
                              {user.employee_name}
                            </Select.Option>}
                        )
                      }
                    </Select.OptGroup>
                   })
              }
            </Select> */}
            
            <input onChange={this.handleChange} type="text" name="summary" placeholder='Summary'/>
            
            {/* <select onChange={this.handleChange} name="category" defaultValue={this.state.categories[1]} disabled id="">
              <option value=""  disabled hidden>Categorization</option>
             {
               this.state.categories.map((item)=>{
                 return <option key={item} value={item}>{item.toString()}</option>  
                })
             }
            </select> */}
            
            {/* <select onChange={this.handleChange} defaultValue={2} disabled name="priority" id="">
              <option value="" disabled hidden>Priority</option>
              <option value="2">High</option>
              <option value="3">Medium</option>
              <option value="4">Low</option>
            </select> */}
            <AudioRecorder audio={this.state.audio} setAudio={this.setAudio}/>
            <label htmlFor="fileInput" name="file" className='support_file_upload'>
              <span>{this.state.file != ''?this.state.file.name:'File Upload'}</span>
              <input onChange={this.handleChange} name="file" id="fileInput" type="file"  />
              <img src={require('../assets/images/upload (1).png')} />
            </label>
            <textarea
                    onChange={this.handleChange}
                    type="text"
                    name="description"
                    placeholder="Description"
                    maxLength="1024"
            ></textarea>
            {this.state.warning_msg!=''?<span className='text-danger'>*{this.state.warning_msg}</span>:''}
            <button disabled={this.state.disabled} className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4" type="submit" onClick={this.handleSubmit}>Submit</button>
          </form>
        </div>
      </>
    )
  }
}

export default withRouter(SupportTicketForm);

function AudioRecorder({audio,setAudio}) {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);
  const mimeType = "audio/webm"

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
        try {
            const streamData = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,
            });
            setPermission(true);
            // setStream(streamData);
            return streamData
        } catch (err) {
            alert(err.message);
            return null;
        }
    } else {
        alert("The MediaRecorder API is not supported in your browser.");
    }
};

  const startRecording = async () => {
    const stream = await getMicrophonePermission()
    if(!stream)
      return
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
       if (typeof event.data === "undefined") return;
       if (event.data.size === 0) return;
       localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
       const audioBlob = new Blob(audioChunks, { type: mimeType });
       const audioUrl = URL.createObjectURL(audioBlob);
       setAudio(audioUrl);
       setAudioChunks([]);
    };
  };
  
  const deleteRecording = ()=>{
    setAudio(null);
    setAudioChunks(null);
  }

  return (
    <>
     
      <div className="st_audio_input">
        <div>
          {audio?  <audio  src={audio} controls></audio> : 
          recordingStatus === "inactive"? "Record voice message":"Recording..."}
        </div>
        <div className="audio-controls">
          {
            (audio && recordingStatus === "inactive") ? 
            <Button
            onClick={deleteRecording}
            icon={<DeleteFilled/>}/>:null
          }
          
          {recordingStatus === "inactive" ? (
          <Button
          onClick={startRecording}
          icon={<AudioFilled/>}/>
          ) : null}
          {recordingStatus === "recording" ? (
            <Button
            onClick={stopRecording}
            icon={<BorderOutlined/>}/>
          ) : null}
        </div>
      </div>
    </>
  )
}