import React, { Component } from 'react';
import {useHistory} from 'react-router-dom';
export default class ChatSupportBtn extends Component {

    constructor(props){
      super(props);
    }

    handleClick = (e)=>{
      e.preventDefault();
      this.props.history.push("/createsupportticket");
    }

    render() {
    return (
      <button className='chatSupportBtn'
        onClick={this.handleClick}>
            <img src={require("../assets/images/live-chat.png")}/>
      </button>
    )
  }
}
