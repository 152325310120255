import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

class Physical_NACH_register extends Component {
  constructor() {
    super();
    this.state = {
      file: "",
      warning_msg: "",
      uid_warning_msg: "",
      bankdetail: [],
      nachpartner: [],
      nachp: "",
      bankac: "",
      nachuserid: "",
      isErrOpen: false,
      isSuccess: false,
      isOrderidnull: false,
      isOrderidnotnull: false,
      isUpDowload: true,
      isLoading: false,
    };
  }
  componentDidMount() {
    document.title = "NACH Registration";
    this.getbankdetail();
    this.getnachpartner();
  }
  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name == "file") value = e.target.files[0];
    this.setState({ [name]: value });
  };
  getnachpartner() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          nachpartner: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  getbankdetail() {
    this.setState({ isLoading: true });
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    //console.log("Get Bank Detail");
    const uid = localStorage.getItem("in_userid");
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      household_id: household_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/get/household/bank/details?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //   });
        // }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          bankdetail: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  validateuser = (e) => {
    e.preventDefault();
    const uname = localStorage.getItem("in_username");
    let uid_warning_msg = "";
    console.log("this.state.nachuserid", this.state.nachuserid);
    console.log("uname", uname);
    if (this.state.nachuserid == "") {
      console.log("1");
      uid_warning_msg = "Please enter username";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
    if (this.state.nachuserid !== uname) {
      console.log("2");
      uid_warning_msg = "Please enter valid usnername ";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // this.setState({
    //   isSuccess: true,
    // });
    let warning_msg = "";
    if (this.state.bankac == "") {
      warning_msg = "Please choose bank account";
      this.setState({ warning_msg });
      return;
    }
    if (this.state.nachp == "") {
      warning_msg = "Please choose NACH Partner";
      this.setState({ warning_msg });
      return;
    }
    let bankacno = this.state.bankac.split("~")[0];
    let nach_order_id = this.state.bankac.split("~")[1];
    let nach_token_id = this.state.bankac.split("~")[2];
    let filename = this.state.bankac.split("~")[3];
    let upload_link = this.state.bankac.split("~")[4];
    if (
      nach_order_id === null ||
      nach_order_id === "" ||
      nach_token_id === "" ||
      nach_token_id === null
    ) {
      this.setState({
        isOrderidnull: true,
      });
    } else {
      this.setState({
        isOrderidnotnull: true,
      });
    }
  };
  redirectclick() {
    window.location.assign(this.state.bankac.split("~")[4]);
  }
  downloadclick() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    let path = this.state.bankac.split("~")[3];
    const formdata = new FormData();
    // formdata.append("bucket_name", path.split("/")[0]);
    // formdata.append("blob_name", path.split("/")[4]);
    formdata.append("blob_url", path);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/download/files/bucket", requestOptions)
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        res.blob().then((img) => {
          //URL.createObjectURL(img)
          const url = window.URL.createObjectURL(img);
          //console.log(URL.createObjectURL(img))
          // this.setState({
          //   viewImg: URL.createObjectURL(img),
          //   isOpen: true,
          // });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", path.split("/")[2]);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    const {
      isErrOpen,
      bankdetail,
      api_error_message,
      nachpartner,
      isSuccess,
      isOrderidnull,
      isOrderidnotnull,
      isUpDowload,
    } = this.state;
    return (
      <>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isSuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isSuccess: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>NACH Registration Success!</p>
            </div>
          </div>
        </div>
        <div
          className={
            isOrderidnull
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOrderidnull: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Please Confirm your userid to register NACH</p>
              <input
                className="form-control"
                name="nachuserid"
                onChange={this.handleChange}
              />
              {this.state.uid_warning_msg != "" ? (
                <span className="text-danger">
                  *{this.state.uid_warning_msg}
                </span>
              ) : (
                ""
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.validateuser}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.setState({ isOrderidnull: false })}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={
            isOrderidnotnull
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isOrderidnotnull: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="col-md-12">
                {this.state.bankac.split("~")[3] &&
                this.state.bankac.split("~")[4] ? (
                  <>
                    <div className="col-md-6 float-left text-center">
                      <p>Download NACH form</p>
                      <button
                        onClick={() => {
                          this.downloadclick();
                        }}
                        className="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                    <div className="col-md-6 float-left text-center">
                      <p>Upload sign NACH form</p>
                      <button
                        onClick={() => {
                          this.redirectclick();
                        }}
                        className="btn btn-primary"
                      >
                        Upload
                      </button>
                    </div>
                  </>
                ) : (
                  "Data Not Found"
                )}
              </div>
            </div>
          </div>
        </div> */}
        <section className="main-sec">
          <div className="container">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Physical NACH Register</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>

            {!this.state.isOrderidnotnull ? (
              <div className="card card-border mt-3">
                <div className="card-body support_form_body">
                  {bankdetail.length > 0 ? (
                    <form onChange={this.handleChange} className="support_form">
                      <select name="bankac" defaultValue="" id="">
                        <option value="" disabled hidden>
                          Select Bank Account
                        </option>
                        {bankdetail.map((item) => {
                          let filename =
                            item.filename !== null || item.filename !== ""
                              ? "~" +
                                item.filename.split("/")[3] +
                                "/" +
                                item.filename.split("/")[4] +
                                "/" +
                                item.filename.split("/")[5]
                              : "~" + item.filename;
                          let upload_link =
                            item.upload_link !== null
                              ? "~" + item.upload_link
                              : "~";
                          return (
                            <option
                              key={item.account_number}
                              value={
                                item.account_number +
                                "~" +
                                item.nach_order_id +
                                "~" +
                                item.nach_token_id +
                                filename +
                                upload_link
                              }
                            >
                              {item.account_number + " - " + item.bank_name}
                            </option>
                          );
                        })}
                      </select>
                      <select defaultValue="" name="nachp" id="">
                        <option value="" disabled hidden>
                          Select NACH Partner
                        </option>
                        {nachpartner.map((item) => {
                          return (
                            <option
                              key={item.payment_vendor_id}
                              value={item.payment_vendor_id}
                            >
                              {item.payment_vendor_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* <label
                    htmlFor="fileInput"
                    name="file"
                    className="support_file_upload"
                  >
                    <span>
                      {this.state.file != ""
                        ? this.state.file.name
                        : "Signed File upload"}
                    </span>
                    <input name="file" id="fileInput" type="file" />
                    <img
                      src={require("../assets/images/upload (1).png")}
                      className="pnachreg-img"
                    />
                  </label> */}
                      {this.state.warning_msg != "" ? (
                        <span className="text-danger">
                          *{this.state.warning_msg}
                        </span>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Register
                      </button>
                    </form>
                  ) : this.state.isLoading ? (
                    "Loading..."
                  ) : (
                    "Bank Deatail Not Found"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.isOrderidnotnull ? (
              <div className="card card-border mt-3">
                <div className="card-body support_form_body">
                  <div className="col-md-12">
                    {this.state.bankac.split("~")[3] &&
                    this.state.bankac.split("~")[4] ? (
                      <>
                        <div className="col-md-6 float-left text-center">
                          <p>Download NACH form</p>
                          <button
                            onClick={() => {
                              this.downloadclick();
                            }}
                            className="btn btn-primary"
                          >
                            Download
                          </button>
                        </div>
                        <div className="col-md-6 float-left text-center">
                          <p>Upload sign NACH form</p>
                          <button
                            onClick={() => {
                              this.redirectclick();
                            }}
                            className="btn btn-primary"
                          >
                            Upload
                          </button>
                        </div>
                      </>
                    ) : (
                      "Data Not Found"
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Physical_NACH_register);
