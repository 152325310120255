import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChatSupportBtn from "./ChatSupportBtn";
import img from "./../assets/images/default-user.png";
import verified from "./../assets/images/verified.png";
import { logout } from "./Utility";
import check from "./../assets/images/checkmark.png";
import check_white from "./../assets/images/check_white_icon.png";
import del_white from "./../assets/images/delete_white_icon.png";
import del_red from "./../assets/images/delete_red_icon.png";
import load_blue from "./../assets/images/load_blue.png";
import load_white from "./../assets/images/load_white_icon.png";
import customFetch from "./apicall/api";

var api_token = "",
  hh_no = "";
class UPI_autopay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchhhno: "",
      hhdata: [],
      hhvpadata: [],
      isSpin: false,
      isAddvpa: false,
      VpaId: "",
      hhid: "",
      isaddVpaRes: false,
      isVpaMessage: "",
      isSetDefault: false,
      isValidate: 0,
      familyData: [],
      family_member_id: "",
      effective_designation_id: "",
      Family_member_err: "",
      setdefault: 0,
      time: {
        minutes: 0,
        seconds: 0,
      },
      duration: 5 * 60 * 1000,
      timer: null,
      reg_vpa: "",
      canResend: false,
      isDefault: 0,
      isDelete: false,
      isInternet: false,
      remarkErr: "",
      isHistory: false,
      HistoryData: [],
      otherScreen: "",
    };
  }
  componentDidMount() {
    this.checkForRequest();
  }

  checkForRequest() {
    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);

    api_token = params.get("api_token");
    hh_no = params.get("lead_id");
    let e_id = "";

    if (api_token == null || hh_no == null) {
      // console.log("HELLOOops.location.state.api_token);

      api_token =
        (this.props.location && this.props.location.state.api_token) || {};

      const { effective_designation_id } =
        (this.props.location && this.props.location.state) || {};
      this.setState({ effective_designation_id: effective_designation_id });

      this.state.user_id = localStorage.getItem("in_userid");
      this.state.showSearch = true;
    } else {
      e_id = params.get("e_id");
      if (e_id == null || e_id == "null" || e_id == "") {
        this.props.history.goBack();
      } else {
        localStorage.setItem("access_token", api_token);
        this.state.searchhhno = hh_no;
        this.state.effective_designation_id = params.get("e_id");
        this.setState({
          otherScreen: 1,
        });
        this.gethhdetail();
      }
    }
  }

  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);

    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    return {
      minutes,
      seconds,
    };
  }
  start() {
    if (!this.state.timer) {
      this.state.startTime = Date.now();
      this.timer = window.setInterval(() => this.run(), 10);
      this.setState({
        reg_vpa: this.state.VpaId,
      });
    }
  }
  run() {
    const diff = Date.now() - this.state.startTime;

    let remaining = this.state.duration - diff;
    if (remaining < 0) {
      remaining = 0;
    }
    this.setState(() => ({
      time: this.msToTime(remaining),
    }));
    if (remaining === 0) {
      window.clearTimeout(this.timer);
      this.timer = null;
      this.setState({ canResend: true, reg_vpa: "" });
    }
  }

  // getfamilyMember() {
  //   const { api_token } =
  //     (this.props.location && this.props.location.state) || {};

  //   const formdata = new FormData();
  //   formdata.append("household_id", this.state.hhdata.household_id);
  //   customFetch(process.env.REACT_APP_URL + "/get/family/tree", {
  //     method: "POST",
  //     headers: { "api-token": api_token },
  //     body: formdata,
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.api_code === 4) {
  //         localStorage.clear();
  //         this.props.history.push("/");
  //       }
  //       this.setState({
  //         familyData: data,
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }

  gethhdetail() {
    this.setState({
      isSpin: true,
    });

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      household_number: this.state.searchhhno,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL +
        "/upi/get/autopay/household/details?" +
        queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
        });
        let sort_vpa = [];
        if (this.state.effective_designation_id != 20) {
          const filteredData = data.map((item) => ({
            ...item,
            family_deatils: item.family_deatils.map((detail) => ({
              ...detail,
              vpa_details: detail.vpa_details.filter(
                (vpa) => vpa.status !== "X"
              ),
            })),
          }));
          console.log("filteredData", filteredData);
          sort_vpa = filteredData[0].family_deatils;
        } else {
          sort_vpa = data[0].family_deatils;
        }
        //sort_vpa = data[0].family_deatils;
        //console.log("sort_vpa", sort_vpa);
        //const count = sort_vpa.filter((item) => item.status === "R").length;
        const filteredData = sort_vpa.filter((obj) => {
          return obj.vpa_details.some(
            (vpa) => vpa.autopay_status_type_flag === "R"
          );
        });
        const countdefaultdata = sort_vpa.filter((obj) => {
          return obj.vpa_details.some((vpa) => vpa.default_flag == 1);
        });
        console.log("Count", filteredData);
        console.log("countdefault", countdefaultdata.length);
        let count = filteredData.length;
        let countdefault = countdefaultdata.length;
        if (count >= 1 && countdefault == 0) {
          //console.log("Count Default", countdefault);
          // const result = sort_vpa.filter((obj) => {
          //   return obj.vpa_details.some((vpa) => vpa.status === "R");
          // });

          const filteredVpaAddresses = sort_vpa
            .map((item) => item.vpa_details)
            .flat()
            .filter((vpa) => vpa.autopay_status_type_flag === "R")
            .map((vpa) => vpa.vpa_address);

          //alert(result[0].vpa_address);
          this.setState(
            {
              hhid: data[0].household_id,
              VpaId: filteredVpaAddresses,
              isDefault: 1,
              setdefault: 1,
            },
            function() {
              this.setDefaultVPA();
            }
          );
        }

        this.setState(
          {
            hhdata: data[0],
            hhvpadata: sort_vpa,
            isSpin: false,
          }
          // ,
          // function () {
          //   this.getfamilyMember();
          // }
        );
      })
      .catch((error) => {
        this.setState({ isSpin: false });
        console.log("error->", error);
      });
  }
  addvpa() {
    this.setState({
      isSpin: true,
    });

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      hh_id: this.state.hhid,
      vpa: this.state.VpaId,
      family_member_id: this.state.family_member_id,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL + "/upi/validate/vpa?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isaddVpaRes: true,
          isSpin: false,
          isVpaMessage: data.message,
          isAddvpa: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  gethistory() {
    this.setState({
      isSpin: true,
    });

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      vpa: this.state.VpaId,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/upi/get/upi/autopay/history?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isHistory: true,
          isSpin: false,
          HistoryData: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  setDefaultVPA() {
    this.setState({
      isSpin: true,
    });

    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};

    const formdata = new FormData();
    formdata.append("hh_id", this.state.hhid);
    formdata.append("vpa", this.state.VpaId);
    customFetch(process.env.REACT_APP_URL + "/upi/set/default/vpa", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (this.state.isDefault == 0) {
          this.setState({
            isaddVpaRes: true,
            isSpin: false,
            isVpaMessage: data.message,
            isSetDefault: false,
          });
        } else {
          this.setState({ isSpin: false });
          if (this.state.setdefault == 1) {
            this.gethhdetail();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  registerHH() {
    let valid = true;
    if (
      this.state.family_member_id == "" ||
      this.state.family_member_id == null
    ) {
      valid = false;
      this.setState({ Family_member_err: "Please Select Family Member" });
    }

    if (valid) {
      this.setState({ isSpin: true });
      // const { api_token } =
      //   (this.props.location && this.props.location.state) || {};

      const formdata = new FormData();
      formdata.append("household_id", this.state.hhid);
      formdata.append("vpa", this.state.VpaId);
      formdata.append("family_member_id", this.state.family_member_id);
      customFetch(process.env.REACT_APP_URL + "/upi/register/autopay", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.api_code === 4) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isaddVpaRes: true,
            isSpin: false,
            isVpaMessage: data[0].message,
            isAddvpa: false,
            isInternet: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.setState({ canResend: false });
    this.start();
  }
  deactiveVPA() {
    if (this.state.remark != null && this.state.remark != "") {
      this.setState({ isSpin: true });
      // const { api_token } =
      //   (this.props.location && this.props.location.state) || {};

      const formdata = new FormData();
      formdata.append("vpa", this.state.VpaId);
      formdata.append("remark", this.state.remark);
      customFetch(process.env.REACT_APP_URL + "/upi/deactive/subscription", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.api_code === 4) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isaddVpaRes: true,
            isSpin: false,
            isVpaMessage: data[0].message,
            isAddvpa: false,
            isDelete: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        remarkErr: "Please Enter Remark",
      });
    }
  }
  UpdateVPAstatus() {
    this.setState({ isSpin: true });

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      vpa: this.state.VpaId,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL + "/upi/get/auth/request/status?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isaddVpaRes: true,
          isSpin: false,
          isVpaMessage: data[0].message,
          isAddvpa: false,
          isDelete: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            this.state.isSetDefault
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              {/* <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => [this.setState({ isSetDefault: false })]}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
              <h3 className="text-center mb-3">
                Are You Sure To Set <strong>{this.state.VpaId}</strong> as
                Default VPA ?
              </h3>
              <div className="row mt-3">
                <div className="vpabutton row col-md-12 text-center">
                  <div className="col-md-6">
                    <button
                      disabled={!this.state.hhid}
                      className="addvpaButton"
                      onClick={() => this.setDefaultVPA()}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="addvpaButton"
                      onClick={() => this.setState({ isSetDefault: false })}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isInternet
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              {/* <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => [this.setState({ isSetDefault: false })]}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
              <h3 className="text-center mb-3">
                Please Confirm Household Has High Speed Internet
              </h3>
              <div className="row mt-3">
                <div className="vpabutton row col-md-12 text-center">
                  <div className="col-md-6">
                    <button
                      disabled={!this.state.hhid}
                      className="addvpaButton"
                      onClick={() => this.registerHH()}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="addvpaButton"
                      onClick={() => this.setState({ isInternet: false })}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isaddVpaRes
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => [
                  this.setState({ isaddVpaRes: false }),
                  this.gethhdetail(),
                ]}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{this.state.isVpaMessage}</p>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isHistory
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isHistory: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <table className="table">
                <thead>
                  <tr>
                    <th>VPA</th>
                    <th>Mobile</th>
                    <th>Vendor</th>
                    <th>Reject Reson</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.HistoryData &&
                    this.state.HistoryData.map((hdata, index) => (
                      <tr id={index}>
                        <td>{hdata.upi_id}</td>
                        <td>{hdata.mobile_no}</td>
                        <td>{hdata.payment_vendor_name}</td>
                        <td>{hdata.rejection_reason}</td>
                        <td>{hdata.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isAddvpa
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div class="modal-content border11">
              <div class="modal-header modalhead">
                <h5 class="modal-title">Add VPA</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({ isAddvpa: false, isValidate: 0 })
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <input
                      className="form-control vpaAddress"
                      value={this.state.VpaId}
                      readOnly={this.state.isValidate == 1}
                      onChange={(e) => this.setState({ VpaId: e.target.value })}
                    />
                  </div>
                  <div className="vpabutton row col-md-12 text-center">
                    <div className="col-md-6">
                      <button
                        disabled={this.state.isValidate == 1}
                        className={
                          this.state.isValidate == 1
                            ? "addvpaButton disable"
                            : "addvpaButton"
                        }
                        onClick={() => this.addvpa()}
                      >
                        Validate
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        disabled={
                          this.state.isValidate == 0 ||
                          (!this.state.canResend &&
                            this.state.reg_vpa == this.state.VpaId)
                        }
                        className={
                          this.state.isValidate == 0 ||
                          (!this.state.canResend &&
                            this.state.reg_vpa == this.state.VpaId)
                            ? "addvpaButton disable"
                            : "addvpaButton"
                        }
                        onClick={() =>
                          this.setState({ isInternet: true, isAddvpa: false })
                        }
                      >
                        Register{" "}
                        {!this.state.canResend &&
                          this.state.reg_vpa == this.state.VpaId &&
                          this.state.time.minutes != 0 &&
                          this.state.time.seconds != 0 && (
                            <span>
                              {this.state.time.minutes +
                                ":" +
                                this.state.time.seconds}
                            </span>
                          )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isDelete
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div class="modal-content border11">
              <div class="modal-header modalhead">
                <h5 class="modal-title">Add Remark</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isDelete: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <textarea
                        className="form-control vpaAddress"
                        name="remark"
                        onChange={(e) =>
                          this.setState({ remark: e.target.value })
                        }
                        value={this.state.remark}
                      >
                        {/* {this.state.remark} */}
                      </textarea>
                      <span className="errmsg">
                        {this.state.remarkErr && this.state.remarkErr}
                      </span>
                    </div>
                  </>

                  <div className="vpabutton col-md-12 text-center">
                    <div>
                      <button
                        className="addvpaButton col-md-3"
                        onClick={() => this.deactiveVPA()}
                      >
                        Deactive
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="utr_navbar mb-2">
              <button
                className="button back_button"
                onClick={() => this.props.history.goBack()}
              >
                <img src={require("../assets/images/back.png")} />
              </button>
              <h3>UPI Autopay</h3>
              <button
                className="button"
                onClick={() => logout(this.state.otherScreen)}
              >
                <img src={require("../assets/images/power-off.png")} alt="" />
              </button>
            </div>
            <div className="search-holder mt-4">
              <div className="row">
                <div className="col-md-10 p-2">
                  <input
                    className="form-control search-box"
                    placeholder="Enter Household Number"
                    onChange={(e) =>
                      this.setState({ searchhhno: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-2 p-2">
                  <button
                    className="btn search-btn"
                    onClick={() => this.gethhdetail()}
                  >
                    <i class="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
              <div className="upi-card-holder">
                {this.state.hhdata.length != 0 && (
                  <section className="sub-card-container">
                    <div className="head-card-container">
                      <div className="name-cont">
                        <div className="household-name">
                          <h1>HH Name : {this.state.hhdata.household_name}</h1>
                        </div>
                        <div className="hh-no">
                          <p>HH ID : {this.state.hhdata.household_number}</p>
                        </div>
                      </div>
                      <div className="br-name">
                        <span>{this.state.hhdata.branch_name}</span>
                      </div>
                    </div>

                    {this.state.hhvpadata &&
                      this.state.hhvpadata.map((hhdata, index) => (
                        <div key={index} className="bottom-card-container pb-4">
                          <div className="bot-hed">
                            <div className="f-head">
                              <div className="sub-hed">
                                <figure className="f-img">
                                  <img src={img} alt="" />
                                </figure>
                                <div className="hname">
                                  <span>{hhdata.family_member_name}</span>
                                  {hhdata.family_relation_name && (
                                    <p>{hhdata.family_relation_name}</p>
                                  )}
                                </div>
                              </div>
                              <button
                                className="unique-name-click"
                                onClick={() =>
                                  this.setState({
                                    VpaId: "",
                                    isAddvpa: true,
                                    hhid: this.state.hhdata.household_id,
                                    family_member_id: hhdata.family_member_id,
                                    isValidate: 0,
                                  })
                                }
                              >
                                <i className="fa fa-plus"></i> VPA
                              </button>
                            </div>
                            {hhdata.vpa_details.map((vpadata) => (
                              <div className="choosing-container">
                                <ul>
                                  <li className="first">
                                    <input
                                      type="radio"
                                      disabled={
                                        vpadata.autopay_status_type_flag ==
                                          null ||
                                        vpadata.autopay_status_type_flag == ""
                                      }
                                      name="default_vpa"
                                      className="default_vpa"
                                      checked={vpadata.default_flag == 1}
                                      onClick={() =>
                                        this.setState({
                                          VpaId: vpadata.vpa_address,
                                          hhid: this.state.hhdata.household_id,
                                          isSetDefault: true,
                                        })
                                      }
                                    />
                                    <span>{vpadata.vpa_address}</span>
                                  </li>
                                  <li className="verify-sec">
                                    {vpadata.status_type_description != "" && (
                                      <li className="second">
                                        {vpadata.autopay_status_type_flag ==
                                          "R" && <img src={verified} alt="" />}
                                        <span
                                          className={
                                            vpadata.autopay_status_type_flag ==
                                              "J" && "email-error"
                                          }
                                        >
                                          {vpadata.status_type_description}
                                        </span>
                                      </li>
                                    )}
                                    <li className="verify-sec2">
                                      {vpadata.status != "X" &&
                                        vpadata.autopay_status_type_flag !=
                                          "R" && (
                                          <li
                                            className="third unique-name-click"
                                            onClick={() =>
                                              this.setState({
                                                isValidate: 1,
                                                VpaId: vpadata.vpa_address,
                                                isAddvpa: true,
                                                hhid: this.state.hhdata
                                                  .household_id,
                                                family_member_id:
                                                  hhdata.family_member_id,
                                              })
                                            }
                                          >
                                            <img src={check} alt="" />
                                          </li>
                                        )
                                      // : (
                                      //   <li className="third not-allowed">
                                      //     <img
                                      //       src={require("../assets/images/check_white_icon.png")}
                                      //       alt=""
                                      //     />
                                      //   </li>
                                      // )
                                      }
                                      {process.env.REACT_APP_effective_designation_id.split(
                                        ","
                                      ).includes(
                                        this.state.effective_designation_id.toString()
                                      ) &&
                                        vpadata.status != "X" && (
                                          <li
                                            className="forth unique-name-click"
                                            onClick={() =>
                                              this.setState({
                                                isDelete: true,
                                                VpaId: vpadata.vpa_address,
                                                remark: "",
                                              })
                                            }
                                          >
                                            <img src={del_red} alt="" />
                                          </li>
                                        )}
                                      {this.state.effective_designation_id ==
                                        14 &&
                                        vpadata.autopay_status_type_flag !=
                                          "R" && (
                                          <li
                                            className="forth unique-name-click"
                                            onClick={() =>
                                              this.setState({
                                                isDelete: true,
                                                VpaId: vpadata.vpa_address,
                                                remark: "",
                                              })
                                            }
                                          >
                                            <img src={del_red} alt="" />
                                          </li>
                                        )}

                                      {vpadata.autopay_status_type_flag != "R" &&
                                        vpadata.status != "X" && (
                                          <li
                                            className="fifth unique-name-click"
                                            onClick={() => {
                                              this.setState(
                                                {
                                                  VpaId: vpadata.vpa_address,
                                                },
                                                function() {
                                                  this.UpdateVPAstatus();
                                                }
                                              );
                                            }}
                                          >
                                            <img src={load_blue} alt="" />
                                          </li>
                                        )
                                      // : (
                                      //   <li className="fifth not-allowed">
                                      //     <img
                                      //       src={require("../assets/images/load_white_icon.png")}
                                      //       alt=""
                                      //     />
                                      //   </li>
                                      // )
                                      }
                                      <li
                                        className="sixth unique-name-click"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              VpaId: vpadata.vpa_address,
                                            },
                                            function() {
                                              this.gethistory();
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={require("../assets/images/right-arrow.png")}
                                          alt=""
                                        />
                                      </li>
                                    </li>
                                  </li>
                                </ul>
                              </div>
                            ))}
                            {/* <div className="choosing-container">
                              <ul>
                                <li className="first">
                                  <input type="radio" name="" id="" />
                                  <span>9611152364@ybl</span>
                                </li>
                                <li className="verify-sec">
                                  <li className="second no">
                                    <img src={verified} alt="" />
                                    <span>Register</span>
                                  </li>
                                  <li className="verify-sec2">
                                    <li className="third">
                                      <img src={check_white} alt="" />
                                    </li>
                                    <li className="forth">
                                      <img src={del_white} alt="" />
                                    </li>
                                    <li className="fifth">
                                      <img src={load_white} alt="" />
                                    </li>
                                  </li>
                                </li>
                              </ul>
                            </div>
                            <div className="choosing-container">
                              <ul>
                                <li className="first">
                                  <input type="radio" name="" id="" />
                                  <span>9611454568@ybl</span>
                                </li>
                                <li className="verify-sec">
                                  <li className="second no">
                                    <img src={verified} alt="" />
                                    <span>Register</span>
                                  </li>
                                  <li className="verify-sec2">
                                    <li className="third n">
                                      <img src={check_white} alt="" />
                                    </li>
                                    <li className="forth n">
                                      <img src={del_white} alt="" />
                                    </li>
                                    <li className="fifth n">
                                      <img src={load_white} alt="" />
                                    </li>
                                  </li>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      ))}
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(UPI_autopay);
