import React, { Component } from "react";

class test extends Component {
  render() {
    return (
      <div>
        <iframe
          title="ATP - 20230331 v2"
          width="1140"
          height="541.25"
          src="https://app.powerbi.com/reportEmbed?reportId=6e8bc538-6c83-4223-874d-f4100f9024c5&autoAuth=true&ctid=a0598ff8-5882-45d8-8dd2-a62739f7c810"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    );
  }
}

export default test;
