import React, { Component } from 'react';
import Papa from "papaparse";
import customFetch from "./apicall/api";
class Utr extends Component {
    constructor(props) {
      super(props)
      this.InputFile = React.createRef();
      this.state = {
        rows : [],
        isStatus : false,
        isLoading : false,
        isUploaded : false,
        isDisabled: false
      }
    }

    handleBrowse = ()=>{
        this.InputFile.current.click();
    }

    handleFileChange = (event)=>{
        if(!event.target.files[0])
        return

        Papa.parse(event.target.files[0], {
            header: true,
            complete: (result)=> {
                let data = result.data.filter((obj)=>{
                    return (obj['loan_id'] != null && obj['utr'] != null && 
                    obj['ifsc'] != null && obj['account_number'] != null && obj['amount'] != null && obj['remittance_date'] != null)
                })
                this.setState({rows:data,isStatus:false})
            }
        });
        event.target.value = null;
        this.setState({isDisabled:false})
    }

    handleUpload = async ()=>{
        if(this.state.rows.length == 0)
            return 
        const response = await this.uploadAPI()
        if(response){
            this.processUploadResponse(response)
            this.setState({isDisabled:true})
            alert('File Uploaded Successfully')
        }
        
    }

    uploadAPI =async () =>{
        try{
            const  api_token  = localStorage.getItem('api_token');
            const _headers = new Headers();
            const body = {
                'userid' : localStorage.getItem('in_userid'),
                'data' : this.state.rows
            }
            this.setState({isDisabled:true,isLoading:true})
            const response = await customFetch(process.env.REACT_APP_URL+'/utr/upload', {
                method: 'POST',
                headers: {
                "api-token": api_token,
                "Content-Type": 'application/json'
                },
                body: JSON.stringify(body)
            });
            
            // console.log(response.status)

            if(response.status != 200){
                alert('Error while uploading file, Upload correct format')
                this.setState({isDisabled:false})
                throw "Internal Server Error"
            }
            let data = await response.json();
            // console.log(data)
            return data;
        }
        catch(e){
            console.error(e);
        }
        finally{
            this.setState({isLoading:false})
        }
    }

    processUploadResponse = (response)=>{
        const failedRows = response['failed_rows'];
        const uploadedRows = response['uploaded_rows'];
        const updatedRows = this.state.rows.map((row) => {
            const failedRow = failedRows.find((failedRow) => failedRow.utr === row.utr);
            if (failedRow) {
            return {
                ...row,
                error: failedRow.error,
                };
            }
            const uploadedRow = uploadedRows.find((uploadedRow) => uploadedRow.utr === row.utr);
            if (uploadedRow) {
                return {
                    ...row,
                    error: '',
                };
            }
            return row;
        });
        // console.log(updatedRows)
        this.setState({
            rows: updatedRows,
            isStatus: true
        });
    }

    handleDownloadFormat = ()=>{
        const json = [{
            "loan_id": "",
            "account_number": "",
            "ifsc": "",
            "utr": "",
            "remittance_date": "YYYY-MM-DD",
            "amount": ""
        }]
        const csv_text = Papa.unparse(json,{header:true})
        // console.log(csv_text)
        const element = document.createElement("a");
        const file = new Blob([csv_text], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "UTR Data.csv";
        document.body.appendChild(element);
        element.click();
    }

    render() {

        return (
            <div className='container'>
                <div className="utr_navbar">
                    <button className='button back_button' onClick={() => this.props.history.goBack()}>
                        <img
                        src={require("../assets/images/back.png")}
                        />
                    </button>
                    <h3>UTR upload</h3>
                    <button className='button' onClick={this.logout}>
                        <img
                            src={require("../assets/images/power-off.png")}
                            alt=""
                        />
                    </button>
                </div>    
                <div className='utr_main_block'>
                    <div className='utr_action_block'>
                        <h4 className='utr_action_block_header'><b>Upload file :</b> <button onClick={this.handleDownloadFormat} className='btn btn-sm btn-secondary'>Download Format</button></h4>
                        <div className='utr_action_button_block'>
                            <input hidden type="file" ref={this.InputFile} onChange={this.handleFileChange} accept=".csv"/>
                            <button className='btn btn-md btn-primary' onClick={this.handleBrowse}>Browse File</button>
                            <button className='btn btn-md btn-primary' disabled={this.state.isDisabled} onClick={this.handleUpload}>{this.state.isLoading?<i className="fa fa-circle-o-notch fa-spin"></i>:''} Upload</button>
                        </div>
                        <div className='utr_rows_count'>{this.state.rows.length !=0?this.state.rows.length+' records':''}</div>
                    </div>
                    
                    <div className='utr_table_block'>
                        
                        <table className='utr_data_table'>
                            <thead>
                                <tr>
                                    <th>Loan ID</th>
                                    <th>Beneficiary AC no</th>
                                    <th>IFSC code</th>
                                    <th>UTR No</th>
                                    <th>Disbursement Date</th>
                                    <th>Amount</th>
                                    {this.state.isStatus?<th>Status</th>:null}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.rows.map((row)=>{
                                            return <tr key={row['utr']}>
                                            <td>{row['loan_id']}</td>
                                            <td>{row['account_number']}</td>
                                            <td>{row['ifsc']}</td>
                                            <td>{row['utr']}</td>
                                            <td>{row['remittance_date']}</td>
                                            <td>{row['amount']}</td>
                                            {this.state.isStatus?<td className={`${row['error']==''?'utr_upload_success':'utr_upload_error'}`}>{row['error']==''?'Uploaded successfully!':row['error']}</td>:null}
                                        </tr>
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Utr;
