import React,{useEffect,useState,useContext} from 'react';
import {Select,Modal,Form,Radio,Button} from 'antd';
import { PermissionsContext } from './PermissionContext';

export default function PEASelect({element_id,options,...props}) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [elementId, setElementId] = useState(element_id);
    const context = useContext(PermissionsContext);

    let permission = null;
    let clickTimer = null;  

    const handleLongClick = ()=>{
      if(!context.longPressActive){
        context.setLongPressActive(true);
        const elementDetail = context.getElementById(elementId)
        if(!elementDetail.element_id){
          context.saveElement([elementId]);
        }
        setIsModalVisible(true);
      }
    }

    const handleMouseDown = () => {
      clickTimer = setTimeout(handleLongClick, 500);
    };
  
    const handleMouseUp = () => {
      clearTimeout(clickTimer);
      if(props.onClick && !context.longPressActive){
        props.onClick();
      }
    };

    const permissionController = (input_options)=>{
        const _options = [];
        input_options.forEach((option)=>{
            const optionElementId = constructElementId(option.value,elementId)
            permission = context.getPermissionById(optionElementId);
            if(permission=='D')
                _options.push({...option,disabled:true})
            else if(permission=='H') 
                return
            else
                _options.push(option)
        })
        return _options;
    }

    const handleFormSubmit = async (element_permissions) => { 
        resetLongPressStatus();

        const unsavedElementsList = [];
        element_permissions.forEach((permission)=>{
            const element_obj_id = context.getElementById(permission.element_id).element_id;
            if(!element_obj_id){
                unsavedElementsList.push(permission.element_id)
            }
        })
        await context.saveElement(unsavedElementsList);
        const savedElementsMap = await context.fetchElements();
        
        element_permissions.forEach((permission)=>{
            context.saveElementPermission({'element_id':savedElementsMap[permission.element_id].element_id,'permission':permission.permission})
        })
    }

    const resetLongPressStatus = ()=>{
      context.setLongPressActive(false);
    }

    permission = context.getPermissionById(elementId);
    let isFeatureHidden = false;
    let isFeatureDisabled = false;

    if(permission){
        isFeatureHidden = permission==='H';
        isFeatureDisabled = permission==='D';
    }
    // else if(!context.isAdminMode){
    //     return null
    // }
    
    if (!context.isAdminMode && isFeatureHidden) {
        return null;
    }

    if(context.isAdminMode){
        return (
            <div
                onMouseDown={(event)=>{event.stopPropagation();handleMouseDown()}}
                onMouseUp={(event)=>{event.stopPropagation();handleMouseUp()}}
                style={{all:'unset',outline:'2px solid orange'}}
            >
                <EditComponentModal 
                    elementId={elementId}
                    initialValues={permission}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    resetLongPressStatus={resetLongPressStatus}
                    handleFormSubmit={handleFormSubmit}
                    options={options}
                />
                <Select options={options}
                    {...props}
                />
            </div>

        )
    }
       
    return (
        <Select options={permissionController(options)}
            {...props}
            disabled={isFeatureDisabled}
        />
    )

}

const EditComponentModal = ({initialValues,elementId,isModalVisible,setIsModalVisible,handleFormSubmit,resetLongPressStatus,options,}) => {

    const context = useContext(PermissionsContext);
    const [deselectedOptions,setDeselectedOptions] =useState([]);

    const hiddenOptions = [];
    const disabledOptions = []

    options.forEach((option)=>{
        const _optionElementId = constructElementId(option.value,elementId);
        const permission = context.getPermissionById(_optionElementId);
        if(permission == 'H')
            hiddenOptions.push(option.value);
        if(permission == 'D')
            disabledOptions.push(option.value);
    })

    const handleCancel = ()=>{
      resetLongPressStatus();
      setIsModalVisible(false);
    }
    
    const formSubmitHandler = (values)=>{
        // {'element_id':'id','element_permission':'H'}
        let _values = [];
        _values.push({'element_id':elementId,'permission':values.element_permission});
        values.hidden_options.forEach((option)=>{
            _values.push({'element_id':constructElementId(option,elementId),'permission':'H'});
        })
        values.disabled_options.forEach((option)=>{
            _values.push({'element_id':constructElementId(option,elementId),'permission':'D'});
        })
        deselectedOptions.forEach((option)=>{
            _values.push({'element_id':constructElementId(option,elementId),'permission':'E'});
        })
        _values = _values.filter((item)=>{
            const _permission = context.getPermissionById(item.element_id);
            if(_permission){
               return _permission != item.permission;
            }
            return true; 
        })
        handleFormSubmit(_values);
    }

    const onDeselect = (value)=>{
        setDeselectedOptions([...deselectedOptions,value]);
    }

    const onSelect = (value) =>{
        const _deselectedItems = deselectedOptions.filter((option)=>{return option!=value})
        setDeselectedOptions(_deselectedItems)
    }

    return (
        <>
            <Modal
                title={`Edit Permission-${elementId}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    name="editComponentForm"
                    onFinish={(values) => {
                        formSubmitHandler(values);
                        setIsModalVisible(false);
                    }}
                >
                  <Form.Item
                      label="Select Element Permission"
                      name="element_permission"
                      initialValue={initialValues || 'E'}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="E">Enabled</Radio.Button>
                      <Radio.Button value="D">Disabled</Radio.Button>
                      <Radio.Button value="H">Hidden</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                      label="Hidden Options"
                      name="hidden_options"
                      initialValue={hiddenOptions}
                  >
                    <Select mode="multiple" 
                        onDeselect={onDeselect}
                        onSelect={onSelect}
                        placeholder="Select options to hide" 
                        options={options}
                    />
                  </Form.Item>
                  <Form.Item
                      label="Disabled Options"
                      name="disabled_options"
                      initialValue={disabledOptions}
                  >
                    <Select mode="multiple" 
                        onDeselect={onDeselect}
                        onSelect={onSelect}
                        placeholder="Select options to disable" 
                        options={options}
                    />
                  </Form.Item>
                  <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save 
                      </Button>
                  </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

function constructElementId(optionValue,elementId){
    const regex = /[a-zA-Z0-9_]/g;
    const validOptionValue = optionValue.toString().replace(/[^a-zA-Z0-9_]/g, "");
    const element_id = `${elementId}_selectoption_${validOptionValue}`;
    return element_id;
}