import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import readXlsxFile from "read-excel-file";

import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import customFetch from "./apicall/api";
const $ = window.$;

class NACH_Mandate_Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrOpen: false,
      api_error_message: "",
      // nachpartner: [],
      // nachpartner: [],
      partnerdata: "",
      filedata: "",
      uplderr: "",
      upldfile: "",
      upldfiledata: [],
      preupldclm: [],
      csvheader: [],
      isFileError: false,
      isVisible: false,
    };
  }
  componentDidMount() {
    // this.fetchProduct();
    //this.getnachpartner();
    this.getprecol();
  }
  getnachpartner() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          nachpartner: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  logout() {
    localStorage.clear();
    this.props.history.push("/");
  }
  ChangeXLSXHandler(upldfile) {
    readXlsxFile(upldfile).then((rows) => {
      const headers = rows[0];
      const delim = ",";
      rows.shift();
      const newArray = rows.map((row, i) => {
        const values = row.toString().split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
        }, {});
        return eachObject;
      });

      this.setState(
        {
          upldfiledata: newArray.slice(0, -1),
          csvheader: headers,
        },
        () =>
          this.state.csvheader.length > 0 && this.state.preupldclm.length > 0
            ? $(" td.diffcol").length === 0
              ? this.setState({ isVisible: false })
              : this.setState({ isVisible: true })
            : ""
      );
    });
  }
  ChangeCSVHandler(upldfile) {
    const file = upldfile;
    const reader = new FileReader();

    reader.onload = function(e) {
      const str = e.target.result;
      //console.log("text", str);
      //this.processCSV()
      const delim = ",";
      let headers = str
        .slice(0, str.indexOf("\n"))
        .trim()
        .split(delim);
      //console.log("Csv Header", headers);
      const rows = str.slice(str.indexOf("\n") + 1).split("\n");

      const newArray = rows.map((row) => {
        //console.log("new array row csv", row);
        const values = row.split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
        }, {});
        return eachObject;
      });

      //console.log("newArray", newArray);
      const isBinary = this.checkBinaryData(headers);
      if (isBinary) {
        headers = "";
        this.setState({
          isFileError: true,
        });
      }
      this.setState(
        {
          upldfiledata: newArray.slice(0, -1),
          csvheader: headers,
          isFileError: false,
        },
        () =>
          this.state.csvheader.length > 0 && this.state.preupldclm.length > 0
            ? $(" td.diffcol").length === 0
              ? this.setState({ isVisible: false })
              : this.setState({ isVisible: true })
            : ""
      );
    }.bind(this);

    reader.readAsText(file);
  }

  checkBinaryData(inputData) {
    const binaryString = inputData[0]; // Get the first element of the array

    // Iterate through each character of the input string
    for (let i = 0; i < binaryString.length; i++) {
      // Check if the character falls outside the range of printable ASCII characters
      const charCode = binaryString.charCodeAt(i);
      if (charCode < 32 || charCode > 126) {
        // If it's outside the printable ASCII range, consider it binary
        return true;
      }
    }
    // If all characters are within the printable ASCII range, it's not binary
    return false;
  }

  readuploadfile(event) {
    const upldfile = event.target.files[0];
    if (upldfile) {
      this.setState({
        upldfile: event.target.files[0],
      });
      if (
        upldfile["name"].split(".").pop() === "csv" ||
        upldfile["name"].split(".").pop() === "CSV"
      ) {
        this.ChangeCSVHandler(upldfile);
      } else {
        alert("CSV File Only");
      }
      // if (
      //   upldfile["name"].split(".").pop() === "xlsx" ||
      //   upldfile["name"].split(".").pop() === "XLSX"
      // ) {
      //   this.ChangeXLSXHandler(upldfile);
      // }
    }
  }
  getprecol = (e) => {
    console.log("Change");
    //const name = e.target.name;
    // let value = e.target.value;
    // this.setState({ [name]: value });

    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      process: "mandate",
      purpose: "upload",
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL + "/get/vendor/fields?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          preupldclm: json,
        });
      })
      .catch((error) => console.log("error", error));
  };
  savefile() {
    console.log("savefile", this.state.upldfile);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log(
      "File",
      this.state.partnerdata +
        "_" +
        moment().valueOf() +
        "." +
        this.state.upldfile.name.split(".").pop()
    );
    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    var fname =
      "NACH_Mandate_For_" +
      moment().format("MMM") +
      "_" +
      moment().format("YY") +
      "." +
      this.state.upldfile.name.split(".").pop();
    formdata.append(
      "file",
      this.state.upldfile,
      "NACH_Mandate_For_" +
        moment().format("MMM") +
        "_" +
        moment().format("YY") +
        "." +
        this.state.upldfile.name.split(".").pop()
    );
    formdata.append(
      "blob_url",
      "nach_pull_data/nach_upload_to_nd/mandate_process/" + fname
    );
    //formdata.append("extra_folder", "mandate_process");
    formdata.append(
      "job_name",
      "nach_mandate_upload_" + process.env.REACT_APP_environment
    );
    formdata.append("userid", uid);
    // AT-2142 | Ankur Makavana | 10.07.2023 | Update utr number condition from 10 to 11 charactor
    customFetch(process.env.REACT_APP_URL + "/nach/mandate/upload", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isErrOpen: true,
          api_error_message: json.message,
        });
      })
      .catch((error) => console.log("error", error));
  }
  chkheaderdata(head) {
    console.log("csvheader", this.state.csvheader);
    if (head && this.state.csvheader.includes(head)) {
      // nach_type is one of the specified values
      console.log("is valid.");
      return true;
    } else {
      // nach_type is not one of the specified values
      console.log("is not valid.");
      return false;
    }
  }
  chkdata(head, a) {
    //let isvaliddata = 1;
    // AT-1898 | AT-2024 | Ankur Makavana | 30.06.2023 | No need for Partner dropdown
    // Only allow csv uploads  (currently, both csv and xlsx files are allowed)
    // Data validations:
    // nach_type - Physical or E-NACH
    // ifsc - alphanumeric, length must be 10
    if (a == "") {
      return false;
    }
    // AT-2142 | Ankur Makavana | 10.07.2023 | Update utr number condition from 10 to 11 charactor
    if (head === "nach_type") {
      if (a != undefined) {
        if (a == "" || a == null || (a != "Physical" && a != "E-NACH")) {
          return false;
        }
      } else {
        return false;
      }
    }
    if (head === "ifsc") {
      if (a != undefined) {
        if (/[^0-9A-Za-z]/.test(a) === false || a == "" || a == null) {
          if (a.length != 11) {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  }
  render() {
    const {
      uplderr,
      nachpartner,
      upldfiledata,
      csvheader,
      preupldclm,
      isVisible,
      api_error_message,
      isErrOpen,
    } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    console.log("upldfiledata", upldfiledata);
    console.log("csvheader", csvheader);
    // {
    //   csvheader.length > 0 && preupldclm.length > 0
    //     ? csvheader.length === preupldclm.length &&
    //       csvheader.every(
    //         (val, index) =>
    //           val ===
    //           preupldclm[index]
    //       )
    //       ? this.setState({ isVisible: false })
    //       : this.setState({ isVisible: true })
    //     : "";
    // }
    return (
      <>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isErrOpen: false });
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isVisible
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isVisible: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="text-center">
                Data Not match, Please contact technical team.
              </h3>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Result Of Mandate Registration</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="download-form">
              <div className="product-holder">
                {/* <div className="form-group row">
                  <label
                    htmlFor="inputPassword"
                    className="col-sm-4 col-form-label lbl25"
                  >
                    Partner
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      name="partnerdata"
                      onChange={this.getprecol}
                    >
                      <option value="">Select Partner</option>
                      {nachpartner.map((item) => {
                        return (
                          <option
                            key={item.payment_vendor_id}
                            value={item.payment_vendor_id}
                          >
                            {item.payment_vendor_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="row">
                  <label
                    htmlFor="inputPassword"
                    className="col-sm-6 col-form-label lbl25"
                  >
                    <div className="nach-fileupload-holder">
                      <div className="file-upload1">
                        <div
                          className="upload-img"
                          onClick={() => {
                            // this.['upload'+item.cash_balance_id].click();
                            this["upload"].click();
                          }}
                        >
                          <img
                            src={require("../assets/images/upload (1).png")}
                            primary={false}
                            alt=""
                          />{" "}
                          <span>Upload CSV</span>
                        </div>
                      </div>
                      <input
                        type="file"
                        name="file"
                        accept=".csv, .CSV"
                        onChange={(event) => this.readuploadfile(event)}
                        //ref={(ref) => (this.upload = ref)}
                        ref={(ref) => {
                          this["upload"] = ref;
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                    {/* <span className="upldinst">* csv only</span> */}
                  </label>

                  <div className="col-sm-6 nach-upldbtn">
                    <button
                      className="btn btn-primary upldbtn"
                      disabled={$(" td.diffcol").length === 0 ? false : true}
                      onClick={() => this.savefile()}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                {/* <div className="form-group row mt-4">
                    <div className="col-sm-12 text-center">
                      <button className="btn btn-primary">Download</button>
                    </div>
                  </div> */}
              </div>
            </div>
            <div className="row col">
              <ul className="errmsg">
                <li>
                  Please use .csv files only (Any other file format is not
                  supported)
                </li>
                <li>Make sure that data in the csv file starts from cell A1</li>
                <li>
                  Column headers to be used in csv file should exactly match
                  with the table headers shown below
                </li>
                <li>
                  Column ‘nach_type’ should be either ‘Physical’ or ‘E-NACH’
                </li>
              </ul>
            </div>
            <div className="upload-data">
              {csvheader && this.state.isFileError == false ? (
                <table className="table table-hover" id="findash">
                  <thead>
                    <tr>
                      {// csvheader.length > preupldclm.length
                      //   ? csvheader.map((head) => <th>{head}</th>)
                      //   : preupldclm.map((head) => <th>{head}</th>)
                      preupldclm.map((head) => (
                        <th
                          className={
                            csvheader.length > 0 &&
                            this.chkheaderdata(head) === false
                              ? "diffcol"
                              : ""
                          }
                        >
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvheader.length > 0 &&
                      upldfiledata.map((udata, i) => (
                        <tr key={i}>
                          {// csvheader.length > preupldclm.length
                          //   ? csvheader.map((head, index) => (
                          //       <>
                          //         <td
                          //           className={
                          //             (head &&
                          //               head != preupldclm[index] &&
                          //               preupldclm[index]) ||
                          //             this.chkdata(head, udata[head]) === false
                          //               ? "diffcol"
                          //               : ""
                          //           }
                          //         >
                          //           <div>
                          //             {udata[head]
                          //               ? // && udata[head] !== head
                          //                 udata[head]
                          //               : ""}
                          //           </div>
                          //         </td>
                          //       </>
                          //     ))
                          //   : preupldclm.map((head, index) => (
                          //       <>
                          //         <td
                          //           className={
                          //             (head &&
                          //               head != preupldclm[index] &&
                          //               preupldclm[index]) ||
                          //             this.chkdata(head, udata[head]) === false
                          //               ? "diffcol"
                          //               : ""
                          //           }
                          //         >
                          //           <div>
                          //             {udata[head]
                          //               ? //&& udata[head] !== head
                          //                 udata[head]
                          //               : ""}
                          //           </div>
                          //         </td>
                          //       </>
                          //     ))
                          preupldclm.map((head, index) => (
                            <>
                              <td
                                className={
                                  (head &&
                                    head != preupldclm[index] &&
                                    preupldclm[index]) ||
                                  this.chkdata(head, udata[head]) === false
                                    ? "diffcol"
                                    : ""
                                }
                              >
                                <div>
                                  {udata[head]
                                    ? //&& udata[head] !== head
                                      udata[head]
                                    : ""}
                                </div>
                              </td>
                            </>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <table className="table table-hover" id="findash">
                    <thead>
                      <tr>
                        {preupldclm.map((head) => (
                          <th
                            className={
                              csvheader.length > 0 &&
                              this.chkheaderdata(head) === false
                                ? "diffcol"
                                : ""
                            }
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                  </table>
                  <h3 className="text-center">
                    Issue With File Data. Please try again.
                  </h3>
                </>
              )}
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NACH_Mandate_Registration);
