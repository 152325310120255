import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "./Utility";
class Nach_type extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    document.title = "Nach Type";
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">NACH Type</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="dashboard-card-holder">
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: "/Physical_NACH_register",
                    state: {
                      api_token: api_token,
                      household_id: household_id,                      
                    },
                  });
                }}
              >
                <div className="card-body webkitcenter">
                  <div className="pnachimage-holder">
                    <img src={require("../assets/images/writing.png")} alt="" />
                  </div>
                  <div className="card-heading">
                    <h2>Physical NACH</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div className="card card-border-small col-sm-6 py-2">
                <div className="card-body webkitcenter">
                  <div className="nbnachimage-holder">
                    <img
                      src={require("../assets/images/online-banking.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Net Banking eNach</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div className="card card-border-small col-sm-6 py-2">
                <div className="card-body webkitcenter">
                  <div className="dcnachimage-holder">
                    <img
                      src={require("../assets/images/credit-card.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Debit Card eNach</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>        
      </>
    );
  }
}

export default withRouter(Nach_type);
