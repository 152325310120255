import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChatSupportBtn from "./ChatSupportBtn";

import { logout } from "./Utility";
import customFetch from "./apicall/api";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

class NACH_Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cnames: [],
      isView: false,
      viewFile: "",
      isCreateuser: false,
      nachpartner: [],
      hhid: "",
      hhno: "",
      acno: "",
      ifsc: "",
      nachp: "",
      name_as_per_account: "",
      account_type_name: "",
      nachtyp: "",
      orderSuccess: [],
      isOrderid: false,
      isRegisterdisable: false,
      search_text: "",
      og_data: [],
    };
  }
  componentDidMount() {
    this.fetchDetails();
    //this.getnachpartner();
  }
  // getnachpartner() {
  //   const { api_token, household_id } =
  //     (this.props.location && this.props.location.state) || {};
  //   //console.log("Get Bank Detail");
  //   // const formdata = new FormData();
  //   // formdata.append("household_id", household_id);
  //   customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
  //     method: "POST",
  //     headers: { "api-token": api_token },
  //     //   body: formdata,
  //   })
  //     .then((res) => {
  //       // if (res.headers.get("api_code") > 0) {
  //       //   this.setState({
  //       //     isErrOpen: true,
  //       //     api_error_message: res.headers.get("api_error_message"),
  //       //   });
  //       // }
  //       return res.json();
  //     })

  //     .then((json) => {
  //       if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
  //         localStorage.clear();
  //         this.props.history.push("/");
  //       }
  //       this.setState({
  //         nachpartner: json,
  //       });
  //     })
  //     .catch((error) => console.log("error", error));
  // }
  fetchDetails() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    //const formdata = new FormData();
    customFetch(process.env.REACT_APP_URL + "/get/all/loanaccounts", {
      method: "GET",
      headers: { "api-token": api_token },
      //body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState(
          {
            cnames: json,
            og_data: json,
          },
          function () {
            const $ = window.$;
            var table = $("#findash").DataTable({
              pageLength: 50,
              bProcessing: true,
              iDeferLoading: 57,
              deferRender: true,
              bDestroy: true,
              dom: "Bfrtip",
              buttons: [
                "copy",
                {
                  extend: "excelHtml5",
                  title: "Fin-Ops_" + Date(),
                },
                // {
                //   extend: "pdfHtml5",
                //   title: "Fin-Ops_" + Date(),
                // },
                // "print",
              ],
              initComplete: function () {
                // Select the column whose header we need replaced using its index(0 based)
                this.api()
                  .columns([0, 1, 2, 3])
                  .every(function (i) {
                    if (i != 6) {
                      var column = this;
                      // Put the HTML of the <select /> filter along with any default options
                      var select = $(
                        '<select class="form-control input-sm"><option value="">All</option></select>'
                      )
                        // remove all content from this column's header and
                        // append the above <select /> element HTML code into it
                        .appendTo($(column.header()).empty())
                        // execute callback when an option is selected in our <select /> filter
                        .on("change", function () {
                          // escape special characters for DataTable to perform search
                          var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                          );
                          // Perform the search with the <select /> filter value and re-render the DataTable
                          column
                            .search(val ? "^" + val + "$" : "", true, false)
                            .draw();
                        });
                      // fill the <select /> filter with unique values from the column's data
                      column
                        .data()
                        .unique()
                        .sort()
                        .each(function (d, j) {
                          select.append(
                            "<option value='" + d + "'>" + d + "</option>"
                          );
                        });
                    } else {
                      var column = this;
                      // Put the HTML of the <select /> filter along with any default options
                      var select = $(
                        '<select class="form-control input-sm">' +
                          '<option value="">All</option>' +
                          '<option value="0"> </option>' +
                          '<option value="1">NACH Not Register</option>' +
                          '<option value="2">Signed File Not Uploades</option>' +
                          '<option value="3">Authentication Required</option></select>'
                      )
                        // remove all content from this column's header and
                        // append the above <select /> element HTML code into it
                        .appendTo($(column.header()).empty())
                        // execute callback when an option is selected in our <select /> filter
                        .on("change", function () {
                          // escape special characters for DataTable to perform search
                          var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                          );
                          // Perform the search with the <select /> filter value and re-render the DataTable
                          column.search(val, true, false).draw();
                        });
                    }
                  });
              },
              // disable sorting on the column with the filter in its header.
              columnDefs: [
                {
                  targets: [2],
                  orderable: false,
                },
              ],
            });
            // var columnNumber = 5; //Put your column X number here

            // for (var i = 0; i < table.data().count(); i++) {
            //   table.Rows[i][columnNumber] = "0";
            // }
          }
        );
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  doctemplate() {
    console.log("doctemplate");
  }
  descpartner(household_id, partner_id) {
    let pay_load = {
      household_id: household_id,
      partner_id: partner_id,
    };
    let _body = JSON.stringify(pay_load);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: _body,
    };
    customFetch(
      process.env.REACT_APP_download +
        "/forms/case_description_send_to_partner.php",
      requestOptions
    )
      .then((res) => {
        if (res.headers.get("Content-Type") !== "application/pdf") {
          this.setState({
            isView: true,
          });
        } else {
          res.blob().then((img) => {
            const url = window.URL.createObjectURL(img);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Case_Description_" + household_id + ".pdf"
            );
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  kycvalidate(household_number, partner_code) {
    let pay_load = {
      household_number: household_number,
      partner_code: partner_code,
    };
    let _body = JSON.stringify(pay_load);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: _body,
    };
    customFetch(
      process.env.REACT_APP_download + "/forms/KYCValidation.php",
      requestOptions
    )
      .then((res) => {
        if (res.headers.get("Content-Type") !== "application/pdf") {
          this.setState({
            isView: true,
          });
        } else {
          res.blob().then((img) => {
            const url = window.URL.createObjectURL(img);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              household_number + "_KYC Validation.pdf"
            );
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  loadstatement(loan_documentation_id) {
    let pay_load = {
      loan_documentation_id: loan_documentation_id,
      userid: "5",
    };
    let _body = JSON.stringify(pay_load);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: _body,
    };
    customFetch(
      process.env.REACT_APP_download + "/forms/loan_statement.php",
      requestOptions
    )
      .then((res) => {
        if (res.headers.get("Content-Type") !== "application/pdf") {
          this.setState({
            isView: true,
          });
        } else {
          res.blob().then((img) => {
            const url = window.URL.createObjectURL(img);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              loan_documentation_id + "_loan_statement.pdf"
            );
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  searchCustomer(event) {
    this.state.sort_data = [];

    this.state.cnames = this.state.og_data;

    for (let i = 0; i < this.state.cnames.length; i++) {
      if (
        (this.state.cnames[i].partner_name &&
          this.state.cnames[i].partner_name
            .toLowerCase()
            .indexOf(this.state.search_text.toLowerCase()) >= 0) ||
        (this.state.cnames[i].loan_id &&
          this.state.cnames[i].loan_id
            .toLowerCase()
            .indexOf(this.state.search_text.toLowerCase()) >= 0) ||
        (this.state.cnames[i].household_number &&
          this.state.cnames[i].household_number
            .toLowerCase()
            .indexOf(this.state.search_text.toLowerCase()) >= 0)
      ) {
        this.state.sort_data.push(this.state.cnames[i]);
        // console.log();
      }
    }

    this.state.cnames = this.state.sort_data;

    this.forceUpdate();
  }
  render() {
    const {
      cnames,
      viewFile,
      isView,
      nachpartner,
      isCreateuser,
      orderSuccess,
      isOrderid,
    } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        <div
          className={
            isView
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isView: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="text-center">File Not Found</h3>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Search Household</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="search-holder">
              <div className="container row">
                <div className="col-10">
                  <input
                    className="form-control"
                    placeholder="Enter Loan Id, HH Id or Partner Name"
                    onChange={(e) =>
                      this.setState({
                        search_text: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-2">
                  <img
                    src={require("../assets/images/searchicn.png")}
                    className="searchbtn"
                    onClick={() => this.searchCustomer()}
                  />
                </div>
              </div>
            </div>

            {/* <div className="filter-holder">
              <div className="container row">
                <div className="col-4">
                  <select className="form-control">
                    <option value="">Loan Id</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="form-control">
                    <option value="">HH Id</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="form-control">
                    <option value="">Partner</option>
                  </select>
                </div>
              </div>
            </div> */}
            <div className="data-holder">
              {cnames.length > 0
                ? cnames.map((item) => (
                    <div className="card-data">
                      <div className="head-data">
                        <div className="head-lid">{item.loan_id}</div>
                        <div className="head-hhid">
                          HH ID: {item.household_number}
                        </div>
                      </div>
                      <div className="loan-data">
                        <div className="partner-nm">{item.partner_name}</div>
                        <div className="actionbtn-data">
                          <div className="action-btn container row">
                            <div className="action-label col-12">Action</div>
                            <div className="col-4">
                              <button
                                className="kycbtn"
                                onClick={() =>
                                  this.kycvalidate(
                                    item.household_number,
                                    item.partner_code
                                  )
                                }
                              >
                                KYC Validation Document
                              </button>
                            </div>
                            <div className="col-4">
                              <button
                                hidden={item.status=='I' || item.status=='P' || item.status=='T'?false:true}
                                className="partnerbtn"
                                onClick={() =>{
                                  
                                  this.descpartner(
                                    item.household_id,
                                    item.partner_id
                                  );
                                }
                                }
                              >
                                Auto CAM
                              </button>
                            </div>
                            {item.loan_id ? (
                              <div className="col-4">
                                <button
                                  className="loanbtn"
                                  onClick={() =>
                                    this.loadstatement(item.loan_number)
                                  }
                                >
                                  Loan Statement
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
            {/* <table className="table table-hover" id="findash">
              <thead>
                <tr>
                  <th scope="col">Loan Number</th>
                  <th scope="col">HH No.</th>
                  <th scope="col">Loan Id</th>
                  <th scope="col">Partner Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {cnames.length > 0 ? (
                  cnames.map((item) => (
                    <tr key={item.household_number}>
                      <td>{item.loan_number}</td>
                      <td>{item.household_number}</td>
                      <td>{item.loan_id}</td>
                      <td>{item.partner_name}</td>
                      <td>
                        <button
                          className="btn btn-primary m-1"
                          onClick={() =>
                            this.descpartner(item.household_id, item.partner_id)
                          }
                        >
                          Case Description for Partner
                        </button>
                        <button
                          className="btn btn-primary m-1"
                          onClick={() =>
                            this.kycvalidate(
                              item.household_number,
                              item.partner_code
                            )
                          }
                        >
                          KYC Validation Doc
                        </button>
                        {item.loan_id ? (
                          <button
                            className="btn btn-primary m-1"
                            onClick={() => this.loadstatement(item.loan_number)}
                          >
                            Loan Statement
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="loading">
                        <img
                          src={require("../assets/images/timer-unscreen.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th scope="col">Loan Number</th>
                  <th scope="col">HH No.</th>
                  <th scope="col">Loan Id</th>
                  <th scope="col">Partner Name</th>
                  <th scope="col">Action</th>
                </tr>
              </tfoot>
            </table> */}
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NACH_Download);
