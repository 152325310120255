import { withFeaturePermission } from "./PermissionHOC" 
import PEASelect from "./PESelect"
import React from "react"

export const PESelect = PEASelect;
export const PETextArea = withFeaturePermission((props)=><textarea {...props}>{props.children}</textarea>)
export const PEButton = withFeaturePermission((props)=><button {...props}>{props.children}</button>)
export const PEInput = withFeaturePermission((props)=><input {...props} />)
export const PEDiv = withFeaturePermission((props)=><div {...props}>{props.children}</div>)
export const PESpan = withFeaturePermission((props)=><span {...props}>{props.children}</span>)
export const PEImg = withFeaturePermission((props)=><img {...props}>{props.children}</img>)
export const PETable = withFeaturePermission((props)=><table {...props}>{props.children}</table>)
export const PETr = withFeaturePermission((props)=><tr {...props}>{props.children}</tr>)
export const PETd = withFeaturePermission((props)=><td {...props}>{props.children}</td>)
export const PETbody = withFeaturePermission((props)=><tbody {...props}>{props.children}</tbody>)
export const PEThead = withFeaturePermission((props)=><thead {...props}>{props.children}</thead>)
export const PETh = withFeaturePermission((props)=><th {...props}>{props.children}</th>)
